<template>
  <v-dialog
    v-model="dialogUserEdit"
    persistent
    scrollable
    width="700"
  >
    <v-card class="popup add_store_popup">
      <v-card-title>
      <h3 class="page-title-bar">
        <i class="ico ico-user"></i>
        {{ $t('User Edit') }}
      </h3>
      </v-card-title>
      <v-card-text>

      <div>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              dense
              hide-details
              :label="`${$t('ID')} *`"
              :placeholder="$t('Input the ID')"
              :value="item.account"
              class="form-input"
              disabled
              clearable
               @keydown="clearTabindex"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-if="userEditPassword===true"
              outlined
              dense
              hide-details
              :label="`${$t('Password')} *`"
              type="Password"
              v-model="password"
              class="form-input"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p class="label-txt">{{ $t('Permission Level') }} *</p>
            <v-radio-group
              v-model="item.accessLevel"
              dense
              hide-details
              row
            >
              <v-radio style="margin-right: 15px;"
                v-for="item in accessLevelItems"
                :disabled="getAccessLevelDisabled(item.level)"
                :key="item.level"
                :value="item.level"
                :label="item.name"
                class="form-radio flat"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </div>
      <div>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="name"
              :label="`${$t('Name')} *`"
              :placeholder="$t('Input the name')"
              class="form-input"
              outlined
              dense
              hide-details
              clearable
               @keydown="clearTabindex"
            ></v-text-field>
          </v-col>
          <!-- 삭제
          <v-col>
            <v-text-field outlined dense hide-details
              label="Phone Number"
              placeholder="Input the phone number"
              class="form-input"
              v-model="phoneNumber"
            ></v-text-field>
          </v-col>
           -->
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="email"
              :label="`${$t('Email Address')} *`"
              :placeholder="$t('Input the email address')"
              class="form-input"
              outlined
              dense
              hide-details
              clearable
               @keydown="clearTabindex"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
      <div>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            :disabled="userSaveDisabled"
            @click="clickSave"
            text
            icon
            class="btn"
          >{{ $t('Save') }}
          </v-btn>
          <v-btn
            text
            icon
            @click="closeModal"
            class="btn"
          >{{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '@/plugins/eventBus'
import codes from '@/plugins/codes'
import commons from '@/plugins/commons'
import { getEmailValidation } from '@/plugins/passwordValidator'

const requests = {
  passwordchange: {
    method: 'put',
    url: '/api/common/account/password/temporary'
  }
}
export default {
  props: [
    'dialogUserEdit',
    'accessLevelItems',
    'userAccessLevel'
  ],
  data () {
    return {
      accessLevel: null,
      password: null,
      name: null,
      phoneNumber: null,
      email: null,
      emailAlarm: false,
      emailReport: false,
      item: {},
      userSaveDisabled: false,
      userEditPassword: false,
      initValue: ''
    }
  },
  computed: {

  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    getAccessLevelDisabled (accessLevel) {
      return (Number(accessLevel) <= Number(this.userAccessLevel))
    },
    closeModal () {
      this.$emit('closeModal', false)
    },
    refreshUserInfo () {
      this.closeModal()
      this.$emit('updateUserInfo')
    },
    checkUserInfoValidation (password, email) {
      const emailValidation = getEmailValidation(email)
      if (emailValidation.result === false) {
        EventBus.$emit('messageAlert', this.$t(emailValidation.msg))
        return false
      }
      return true
    },
    clickSave () {
      if (commons.isValidStr(this.email)) this.email = this.email.trim(' ') // 값이 있는경우 trim
      if (this.checkUserInfoValidation(this.password, this.email) === false) return // 빈값이 아닌경우 유효성검사
      if (this.password === null) this.password = '' // null 인경우 API요청할 수 없으므로, ''로 교체
      if (this.email === null) this.email = '' // null 인경우 API요청할 수 없으므로, ''로 교체
      if (this.email === null || this.email === '') {
        EventBus.$emit('messageAlert', this.$t('The user information is invalid.'))
        return false
      }
      if (this.name === null || this.name === '') {
        EventBus.$emit('messageAlert', this.$t('The user information is invalid.'))
        return false
      }
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          myAccessLevel: this.userAccessLevel
        }
      }
      if (this.password !== '' && (this.email !== this.initValue.emailAddress || this.name !== this.initValue.firstName || this.initValue.accessLevel !== this.item.accessLevel)) {
        this.passwordchangeBoth()
      } else if (this.password !== '') {
        this.passwordchange()
      } else if (this.email !== this.initValue.emailAddress || this.name !== this.initValue.firstName || this.initValue.accessLevel !== this.item.accessLevel) {
        const body = {
          account: this.item.account,
          password: this.password,
          firstName: this.name,
          emailAddress: this.email,
          emailAlarm: this.emailAlarm,
          emailReport: this.emailReport,
          accessLevel: this.item.accessLevel
        }
        this.$utils
          .callAxiosWithBody(
            codes.requests.editUser.method,
            codes.requests.editUser.url,
            body,
            config
          )
          .then(res => {
            if (res.data.responseCode === '200') {
              EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
              this.refreshUserInfo()
            }
          })
          .catch(error => {
            console.debug(`Failed to edit the user information. error: ${error}`)
            EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
          })
      }
    },
    passwordchange () {
      const config = { params: {} }
      const body = {
        account: this.item.account,
        newPassword: this.password
      }
      this.$utils
        .callAxiosWithBody(
          requests.passwordchange.method,
          requests.passwordchange.url,
          body,
          config
        )
        .then(res => {
          if (res.data.responseCode === '200') {
            EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
            this.refreshUserInfo()
          }
        })
        .catch(error => {
          console.debug(`Failed to edit the user information. error: ${error}`)
          EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
          this.dialogUserEdit = true
        })
    },
    passwordchangeBoth () {
      const config = { params: {} }
      const body = {
        account: this.item.account,
        newPassword: this.password
      }
      this.$utils
        .callAxiosWithBody(
          requests.passwordchange.method,
          requests.passwordchange.url,
          body,
          config
        )
        .then(res => {
          if (res.data.responseCode === '200') {
            this.tosavedetails()
          }
        })
        .catch(error => {
          console.debug(`Failed to edit the user information. error: ${error}`)
          EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
          this.dialogUserEdit = true
        })
    },
    tosavedetails () {
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          myAccessLevel: this.userAccessLevel
        }
      }
      const body = {
        account: this.item.account,
        password: this.password,
        firstName: this.name,
        emailAddress: this.email,
        emailAlarm: this.emailAlarm,
        emailReport: this.emailReport,
        accessLevel: this.item.accessLevel
      }
      this.$utils
        .callAxiosWithBody(
          codes.requests.editUser.method,
          codes.requests.editUser.url,
          body,
          config
        )
        .then(res => {
          if (res.data.responseCode === '200') {
            EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
            this.refreshUserInfo()
          }
        })
        .catch(error => {
          console.debug(`Failed to edit the user information. error: ${error}`)
          EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
        })
    },
    getMyInfo () {
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          account: this.item.account
        }
      }
      this.$utils.callAxios(
        codes.requests.getUserDetail.method,
        codes.requests.getUserDetail.url,
        config
      ).then(res => {
        if (res.data) {
          this.initValue = res.data
          this.email = res.data.emailAddress
          this.emailAlarm = res.data.emailAlarm
          this.emailReport = res.data.emailReport
          this.phoneNumber = res.data.phoneNumber
          this.name = res.data.firstName
        }
      })
    },
    initData () {
      this.password = null
      this.name = null
      this.phoneNumber = null
      this.email = null
      this.emailAlarm = false
      this.emailReport = false
    }
  },
  mounted () {
    if (this.$store.state.auth.user.accountInfo.accessLevel === '1') {
      this.userEditPassword = true
    }
    EventBus.$on('openUserInfoEditPopup', (item) => {
      Object.assign(this.item, item)
      this.initData()
      this.getMyInfo()
    })
    this.$store.dispatch('auth/getDisabledBtn', '8100').then((flag) => {
      this.userSaveDisabled = flag
    })
    console.log(this.$store.state.auth.user.accountInfo.accessLevel)
  },
  beforeDestroy () {
    EventBus.$off('openUserInfoEditPopup')
  }
}
</script>
<style scoped>
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
