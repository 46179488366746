var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", scrollable: "", width: "700" },
      model: {
        value: _vm.dialogUserEdit,
        callback: function($$v) {
          _vm.dialogUserEdit = $$v
        },
        expression: "dialogUserEdit"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-user" }),
              _vm._v(" " + _vm._s(_vm.$t("User Edit")) + " ")
            ])
          ]),
          _c("v-card-text", [
            _c(
              "div",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("v-text-field", {
                          staticClass: "form-input",
                          attrs: {
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            label: _vm.$t("ID") + " *",
                            placeholder: _vm.$t("Input the ID"),
                            value: _vm.item.account,
                            disabled: "",
                            clearable: ""
                          },
                          on: { keydown: _vm.clearTabindex }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _vm.userEditPassword === true
                          ? _c("v-text-field", {
                              staticClass: "form-input",
                              attrs: {
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                label: _vm.$t("Password") + " *",
                                type: "Password",
                                clearable: ""
                              },
                              model: {
                                value: _vm.password,
                                callback: function($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("p", { staticClass: "label-txt" }, [
                          _vm._v(_vm._s(_vm.$t("Permission Level")) + " *")
                        ]),
                        _c(
                          "v-radio-group",
                          {
                            attrs: { dense: "", "hide-details": "", row: "" },
                            model: {
                              value: _vm.item.accessLevel,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "accessLevel", $$v)
                              },
                              expression: "item.accessLevel"
                            }
                          },
                          _vm._l(_vm.accessLevelItems, function(item) {
                            return _c("v-radio", {
                              key: item.level,
                              staticClass: "form-radio flat",
                              staticStyle: { "margin-right": "15px" },
                              attrs: {
                                disabled: _vm.getAccessLevelDisabled(
                                  item.level
                                ),
                                value: item.level,
                                label: item.name
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c("v-text-field", {
                          staticClass: "form-input",
                          attrs: {
                            label: _vm.$t("Name") + " *",
                            placeholder: _vm.$t("Input the name"),
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            clearable: ""
                          },
                          on: { keydown: _vm.clearTabindex },
                          model: {
                            value: _vm.name,
                            callback: function($$v) {
                              _vm.name = $$v
                            },
                            expression: "name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("v-text-field", {
                          staticClass: "form-input",
                          attrs: {
                            label: _vm.$t("Email Address") + " *",
                            placeholder: _vm.$t("Input the email address"),
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            clearable: ""
                          },
                          on: { keydown: _vm.clearTabindex },
                          model: {
                            value: _vm.email,
                            callback: function($$v) {
                              _vm.email = $$v
                            },
                            expression: "email"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            [
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: {
                        disabled: _vm.userSaveDisabled,
                        text: "",
                        icon: ""
                      },
                      on: { click: _vm.clickSave }
                    },
                    [_vm._v(_vm._s(_vm.$t("Save")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.closeModal }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }